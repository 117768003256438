import React, { useState } from 'react';
import './App.css';
import TripList from './Components/TripList';
import Login from './Components/Login';
import { Toaster } from "react-hot-toast";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthentication = (status) => {
    setIsAuthenticated(status);
  };

  console.log("estoy en App.js");

  // Return the JSX converted to React.createElement calls
  return React.createElement(
    'div',
    { className: 'App' },
    React.createElement(Toaster),
    !isAuthenticated
      ? React.createElement(Login, { onAuthenticate: handleAuthentication })
      : React.createElement(TripList)
  );
}

export default App;
