import React, { useEffect, useRef, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import "../App.css";
import moment from "moment-timezone";
import { toast } from "react-hot-toast";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
require('dotenv').config();

// Define the environment variables, not taken from .env file

const REFRESH_INTERVAL = process.env.REFRESH_INTERVAL || 10000; // Default to 10 seconds
const FLASH_DURATION = process.env.FLASH_DURATION || 20000; // Default to 20 seconds
const TEA_FACTOR = process.env.TEA_FACTOR || 1.5;
const TEA_MAX_TIME = process.env.TEA_MAX_TIME || 10;

const columns = (flashingTripIds) => [
  {
    name: "#Viaje",
    selector: (row) => row.trip_id,
    width: "85px",
    sortFunction: (a, b) => b.trip_id - a.trip_id,
    omit: true,
  },
  {
    name: "Pasajero",
    cell: (row) =>
      React.createElement(
        "div",
        { className: "cell-wrap" },
        `${row.trip_id} - ${row.user_name || ''}`
      ),
    width: "100px",
    sortable: true,
  },
  {
    name: "Conductor/Placa",
    sortable: true,
    cell: (row) =>
      React.createElement(
        "div",
        null,
        React.createElement("div", null, `${row.car_reg_no || ''}`),
        React.createElement(
          "div",
          null,
          `${row.driver_id || ''} - ${row.driver_name || ''} ${row.driver_lastname || ''}`
        )
      ),
    width: "150px",
  },
  {
    name: "Estado",
    selector: (row) => row.trip_status,
    width: "120px",
    sortable: true,
    cell: (row) =>
      React.createElement(
        "div",
        {
          className: flashingTripIds.includes(row.trip_id) ? "flash" : "",
          style: {
            backgroundColor: getStatusBackgroundColor(row.trip_status),
            color: getStatusColor(row.trip_status),
            padding: "8px",
            borderRadius: "4px",
            width: "100%",
            boxSizing: "border-box",
            textAlign: "center",
          },
        },
        row.trip_status || ''
      ),
  },
  {
    name: "Fecha/Hora",
    sortable: true,
    cell: (row) => {
      const colombiaTime = moment(row.trip_date).tz("America/Bogota");
      return React.createElement(
        "div",
        null,
        React.createElement("div", null, colombiaTime.format("DD MMMM YYYY")),
        React.createElement("div", null, colombiaTime.format("HH:mm A"))
      );
    },
    width: "100px",
  },
  {
    name: "TEA (min)",
    sortable: true,
    cell: (row) => {
      if (row.trip_status === "accept" || row.trip_status === "cancel") {
        return React.createElement(
          "div",
          null,
          React.createElement("div", null, `${row.trip_distance || 0} Km`),
          React.createElement(
            "div",
            {
              className:
                (row.trip_distance || 0) * TEA_FACTOR > TEA_MAX_TIME
                  ? "flash"
                  : "",
              style: {
                backgroundColor: getStatusBackgroundColor(row.trip_status),
                color: getStatusColor(row.trip_status),
                padding: "8px",
                borderRadius: "4px",
                width: "100%",
                boxSizing: "border-box",
                textAlign: "center",
              },
            },
            `${Math.round((row.trip_distance || 0) * TEA_FACTOR)} Min`
          )
        );
      }
      return null;
    },
    width: "100px",
  },
  {
    name: "Origen",
    sortable: true,
    selector: (row) => row.trip_from_loc,
    cell: (row) =>
      React.createElement("div", { className: "cell-wrap" }, row.trip_from_loc || ''),
    width: "200px",
  },
  {
    name: "Destino",
    sortable: true,
    selector: (row) => row.trip_to_loc,
    cell: (row) =>
      React.createElement("div", { className: "cell-wrap" }, row.trip_to_loc || ''),
    width: "200px",
  },
];

const customStyles = {
  table: {
    style: {
      font: "Arial, sans-serif",
      width: "100%",
      margin: "0 auto",
    },
  },
  headCells: {
    style: {
      fontSize: "10px",
      fontWeight: "bold",
      backgroundColor: "#FECA07",
      color: "#000000",
      textAlign: "center",
    },
  },
  cells: {
    style: {
      padding: "3px 5px",
      textAlign: "left",
      borderBottom: "1px solid #ddd",
    },
  },
  rows: {
    style: {
      fontSize: "11px",
      borderBottom: "1px solid #ddd",
    },
  },
  highlightOnHoverStyle: {
    backgroundColor: "#ddd",
  },
  selectedStyle: {
    backgroundColor: "#f2f2f2",
  },
};

const getStatusBackgroundColor = (status) => {
  switch (status) {
    case "cancel":
      return "#FF5733";
    case "completed":
      return "#558B2F";
    case "arrive":
      return "#FFA500";
    case "begin":
      return "#90EE90";
    case "request":
      return "#FFA500";
    case "p_cancel_pickup":
    case "paid_cancel":
      return "#FFEB3B";
    case "expired":
      return "#FFA500";
    case "accept":
      return "#ADD8E6";
    default:
      return "#FFFFFF";
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case "cancel":
    case "p_cancel_pickup":
    case "paid_cancel":
    case "cancel_by_r":
      return "#000000";
    default:
      return "#000000";
  }
};

const playCancelSound = () => {
  const audio = new Audio("/assets/alert.mp3");
  audio.play();
};

const TripList = () => {
  const [trips, setTrips] = useState([]);
  const [filters, setFilters] = useState({
    plate: "",
    driver: "",
    status: "",
    origin: "",
  });
  const prevTripsRef = useRef([]);
  const [flashingTripIds, setFlashingTripIds] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch('https://requests.taxizone.com.co:9000/trips');
      const data = await response.json();

      setTrips(data);
      handleTripStatusChange(data);
      prevTripsRef.current = data;

      toast.success("Datos actualizados correctamente", {
        style: {
          background: "green",
          color: "white",
          position: "top-right",
          fontWeight: "bold",
        },
      });
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      toast.error("Error al actualizar los datos");
    }
  }, []);

  useEffect(() => {
    fetchData();
    console.log("Inicializo el primer fetch");

    const interval = setInterval(() => {
      fetchData();
      //console.log("se refresco");
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [fetchData]);

  useEffect(() => {
    if (flashingTripIds.length > 0) {
      const timeout = setTimeout(() => {
        setFlashingTripIds([]);
      }, FLASH_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [flashingTripIds]);

  const handleTripStatusChange = (newTrips) => {
    const prevTrips = prevTripsRef.current;

    newTrips.forEach((newTrip) => {
      const prevTrip = prevTrips.find(
        (trip) => trip.trip_id === newTrip.trip_id
      );
      if (prevTrip && prevTrip.trip_status !== newTrip.trip_status) {
        console.log(
          `Estado del viaje con ID ${newTrip.trip_id} cambió de ${prevTrip.trip_status} a ${newTrip.trip_status}`
        );
        if (newTrip.trip_status === "cancel") {
          setFlashingTripIds((prevIds) => [...prevIds, newTrip.trip_id]);
          playCancelSound();
        }
      }
    });
  };

  const handleClearFilters = () => {
    setFilters({
      plate: "",
      driver: "",
      status: "",
      origin: "",
    });
  };

  const filteredTrips = trips.filter(
    (trip) =>
      (trip.car_reg_no || '').toLowerCase().includes(filters.plate.toLowerCase()) &&
      ((trip.driver_name || '').toLowerCase().includes(filters.driver.toLowerCase()) ||
        (trip.driver_lastname || '').toLowerCase().includes(filters.driver.toLowerCase()) ||
        (trip.driver_id || '').toString().includes(filters.driver)) &&
      (trip.trip_status || '').toLowerCase().includes(filters.status.toLowerCase()) &&
      (trip.trip_from_loc || '').toLowerCase().includes(filters.origin.toLowerCase())
  );

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <div className="App">
      <div className="logo-container">
        <img src={`/assets/logo.png`} alt="Logo" style={{ width: "100px" }} />
      </div>
      <div className="filters-container">
        <input
          type="text"
          className="filter-input"
          placeholder="Buscar por Placa"
          value={filters.plate}
          onChange={(e) => setFilters({ ...filters, plate: e.target.value })}
        />
        <input
          type="text"
          className="filter-input"
          placeholder="Buscar por Conductor"
          value={filters.driver}
          onChange={(e) => setFilters({ ...filters, driver: e.target.value })}
        />
        <select
          className="filter-input"
          value={filters.status}
          onChange={(e) => setFilters({ ...filters, status: e.target.value })}
        >
          <option value="">Todos los Estados</option>
          <option value="accept">Aceptado</option>
          <option value="arrive">Arrivó</option>
          <option value="request">Solicitado</option>
          <option value="cancel">Cancelado</option>
          <option value="p_cancel_pickup">Cancelado x Usuario</option>
          <option value="paid_cancel">Cancelado y pagado</option>
          <option value="expired">Expirado</option>
          <option value="begin">Viaje Comenzado</option>
          <option value="completed">Viaje Completado</option>
        </select>
        <input
          type="text"
          className="filter-input"
          placeholder="Buscar por Origen"
          value={filters.origin}
          onChange={(e) => setFilters({ ...filters, origin: e.target.value })}
        />
      </div>
      <div className="buttons-container centered-buttons">
        <button className="clear-filters-btn" onClick={handleClearFilters}>
          Limpiar Filtros
        </button>
        <button className="refresh-btn" onClick={fetchData}>
          <FontAwesomeIcon icon={faSyncAlt} className="refresh-icon" />
          Actualizar
        </button>
      </div>
      <div className="table-container">
        <DataTable
          columns={columns(flashingTripIds)}
          data={filteredTrips}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          responsive
        />
      </div>
    </div>
  );
};

export default TripList;
