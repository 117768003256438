import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './Login.css';

// Define the port number as a global variable
window.API_PORT = 9000;

const Login = ({ onAuthenticate }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://requests.taxizone.com.co:${window.API_PORT}/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password: password }),
      });

      if (response.ok) {
        // Autenticación exitosa
        console.log('Autenticación exitosa');
        onAuthenticate(true); // Llamar a la función para cambiar el estado de autenticación en App
      } else {
        throw new Error('Usuario o Contraseña Incorrecta');
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return React.createElement(
    'div',
    { className: 'login-container' },
    React.createElement('h2', null, 'TaxiZone Supervisores'),
    React.createElement(
      'div',
      { className: 'logo-container' },
      React.createElement('img', { src: '/assets/logo.png', alt: 'Logo' })
    ),
    React.createElement(
      'form',
      { onSubmit: handleLogin, className: 'login-form' },
      React.createElement('input', {
        type: 'email',
        value: email,
        onChange: (e) => setEmail(e.target.value),
        placeholder: 'Email',
        className: 'login-input',
      }),
      React.createElement('input', {
        type: 'password',
        value: password,
        onChange: (e) => setPassword(e.target.value),
        placeholder: 'Password',
        className: 'login-input',
      }),
      errorMessage && React.createElement('div', { className: 'error-message' }, errorMessage),
      React.createElement('button', { type: 'submit', className: 'submit-button' }, 'Iniciar sesión')
    )
  );
};

Login.propTypes = {
    onAuthenticate: PropTypes.func.isRequired,
  };
export default Login;
